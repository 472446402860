import React, { useState, useEffect } from "react";
import HeadingTitle from "../layout/HeadingTitle";
import Loader from "../../shared/Loader";
const CompetitionStatistics = ({ leagueName, matches, competitionStatistics, seasonId }) => {
const [loading, setLoading] = useState(true);  // Loading state

  useEffect(() => {
    if (competitionStatistics && competitionStatistics.scoredGoals && competitionStatistics.expectedGoals) {
      setLoading(false); 
    } else {
    }
  }, [competitionStatistics]); 

  if (!matches || matches.matches.length === 0) {
    return (
      <div>
        <HeadingTitle title={`${leagueName} statistike - prosjek`} />
        <p>Nema dostupne statistike</p>
      </div>
    );
  }

  // Calculate total and average values from matches
  let totalScoredGoals = 0;
  let totalExpectedGoals = 0;
  let totalShoots = 0;
  let totalShootsOnTarget = 0;
  let totalCornerKicks = 0;
  let totalYellowCards = 0;
  let totalRedCards = 0;
  var totalPlayedMatches = 0;

  matches.matches.forEach((match) => {
    if (match.played === 1) {
      totalPlayedMatches++;
    }
  });

  totalScoredGoals += parseFloat(competitionStatistics.scoredGoals);
  totalExpectedGoals += parseFloat(competitionStatistics.expectedGoals);
  totalShoots += parseFloat(competitionStatistics.totalShoots);
  totalShootsOnTarget += parseFloat(competitionStatistics.shootsOnTarget);
  totalCornerKicks += parseFloat(competitionStatistics.cornerKicks);
  totalYellowCards += parseFloat(competitionStatistics.yellowCards);
  totalRedCards += parseFloat(competitionStatistics.redCards);

  // Calculate average values
  const averageScoredGoals = totalPlayedMatches > 0 ? totalScoredGoals / totalPlayedMatches : 0;
  const averageExpectedGoals = totalPlayedMatches > 0 ? totalExpectedGoals / totalPlayedMatches : 0;
  const averageShoots = totalPlayedMatches > 0 ? totalShoots / totalPlayedMatches : 0;
  const averageShootsOnTarget = totalPlayedMatches > 0 ? totalShootsOnTarget / totalPlayedMatches : 0;
  const averageCornerKicks = totalPlayedMatches > 0 ? totalCornerKicks / totalPlayedMatches : 0;
  const averageYellowCards = totalPlayedMatches > 0 ? totalYellowCards / totalPlayedMatches : 0;
  const averageRedCards = totalPlayedMatches > 0 ? totalRedCards / totalPlayedMatches : 0;

  // Round function to round numbers to 2 decimal places
  const roundToTwoDecimalPlaces = (num) => {
    if (isNaN(num)) return null; // Handle NaN case
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  // Mapping the statistics array with average values
  const statistics = [
    { type: "Postignuti golovi", value: roundToTwoDecimalPlaces(averageScoredGoals) },
    { type: "Očekivani golovi", value: roundToTwoDecimalPlaces(averageExpectedGoals), hideForSeasons: [651, 652]},
    { type: "Ukupno udaraca", value: roundToTwoDecimalPlaces(averageShoots) },
    { type: "Udarci u okvir", value: roundToTwoDecimalPlaces(averageShootsOnTarget) },
    { type: "Korneri", value: roundToTwoDecimalPlaces(averageCornerKicks) },
    { type: "Žuti kartoni", value: roundToTwoDecimalPlaces(averageYellowCards) },
    { type: "Crveni kartoni", value: roundToTwoDecimalPlaces(averageRedCards) },
  ];

  const filteredStatisticsCompetition= statistics.filter(stat => {
    return !(stat.hideForSeasons && stat.hideForSeasons.includes(seasonId));
  });

  return (
    <div>
      <HeadingTitle title={`${leagueName} statistike - prosjek`} />
      <div className="flex w-full px-1">
        {/* Show loader if loading is true */}
        {loading ? (
         <div className="flex justify-center items-center w-full h-[200px]"> {/* Set explicit height */}
         <Loader />
       </div>
          
        ) : (
          <table className="w-full">
            <tbody>
              {filteredStatisticsCompetition.map(
                (stat, index) =>
                  stat.value !== null && (
                    <tr key={index} className="flex w-full p-2 text-scoreText font-fw500 leading-4">
                      <td className="w-1/2 my-1">{stat.type}</td>
                      <td className="w-1/2 text-right font-fw700 leading-4">{stat.value}</td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CompetitionStatistics;
