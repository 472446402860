import React, { useState } from 'react';
import HeadingTitle from '../layout/HeadingTitle';
import ExpandHeadingTitle from '../layout/ExpandingHeadingTitle';
import EventTypeGoal from './events/Goal';
import EventTypeYellowCard from './events/YellowCard';
import EventTypeRedCard from './events/RedCard';
import EventTypePenalty from './events/Penalty';
import EventTypeSubstitution from './events/Substitution';
import EventTypeSecondYellow from './events/SecondYellow';
import EventTypeOwnGoal from './events/OwnGoal';
import EventTypeMissedPenalty from './events/MissedPenalty';
import EventTypePenaltyShootOut from './events/PenaltyShootOut';
// import matchers from "@testing-library/jest-dom/matchers";

function MatchDetailsContent({ matchDetails, matchStats, matchEvents }) {
  const [statsExpanded, setStatsExpanded] = useState(false);
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  const statTypeNames = {
    possessionPercentage: 'Posjed lopte',
    expectedGoals: 'Očekivani golovi (xG)',
    totalScoringAtt: 'Ukupno udaraca',
    bigChanceCreated: 'Velike šanse',
    bigChanceMissed: 'Propuštene velike šanse',
    accuratePass: 'Točna dodavanja',
    fkFoulLost: 'Prekršaji',
    totalOffside: 'Zaleđa',
    cornerTaken: 'Udarci iz kuta',
    totalPass: 'Dodavanja',
    openPlayPass: 'Dodavanja iz igre',
    successfulOpenPlayPass: 'Točna dodavanja iz igre',
    fwdPass: 'Dodavanje prema naprijed',
    backwardPass: 'Dodavanja prema nazad',
    offtargetAttAssist: 'Udarci izvan okvira',
    ontargetScoringAtt: 'Udarci unutar okvira',
    blockedScoringAtt: 'Blokirani udarci',
    hitWoodwork: 'Pogodak u okvir gola',
    attemptsIbox: 'Udarci unutar kaz. prostora',
    attemptsObox: 'Udarci van kaz. prostora',
    attOpenplay: 'Udarci iz igre',
    attSetpiece: 'Udarci iz prekida',
    attCorner: 'Udarci iz kornera',
    expectedGoalsOpenplay: 'Očekivani golovi iz igre',
    expectedGoalsSetplay: 'Očekivani golovi iz prekida',
    expectedGoalsontarget: 'Očekivani golovi u okvir (xGOT)',
    expectedAssists: 'Očekivane asistencije',
    accurateBackZonePass: 'Točna dodavanja na vlastitoj polovici',
    accurateFwdZonePass: 'Točna dodavanja na polovici protivnika',
    totalFwdZonePass: 'Dodavanja na protivničkoj polovici',
    totalBackZonePass: 'Dodavanja na vlastitoj polovici',
    totalFinalThirdPasses: 'Dodavanja na zadnjoj trećini',
    successfulFinalThirdPasses: 'Točna dodavanja  zadnjoj trećini',
    totalLongBalls: 'Duge lopte',
    accurateLongBalls: ' Točne duge lopte',
    totalCross: 'Centaršutevi',
    accurateCross: 'Točni centaršutevi',
    totalThrows: 'Ubacivanje sa strane',
    wonTackle: 'Oduzete lopte',
    interception: 'Presjecanja',
    outfielderBlock: 'Blokirani udarci',
    sixYardBlock: 'Blokirani udarci u 5m',
    totalClearance: 'Otklonjenja opasnost',
    saves: 'Obrane vratara',
    duelWon: 'Osvojeni dueli',
    duelLost: 'Izgubljeni',
    aerialWon: 'Osvojeni zračni dueli',
    aerialLost: 'Izgubljeni zračni dueli',
    totalContest: 'Driblinzi',
    wonContest: 'Uspješni driblinzi',
    totalYellowCard: 'Žuti kartoni ',
    totalRedCard: 'Crveni kartoni',
  };
  const translateStatType = (type) => {
    return statTypeNames[type] || type;
  };
  const requiredStatsNonExpanded = [
    'expectedGoals',
    'totalScoringAtt',
    'successfulFinalThirdPasses',
    'possessionPercentage',
  ];

  const requiredStatsExpanded = [
    'bigChanceCreated',
    'totalPass',
    'totalFwdZonePass',
    'cornerTaken',
    'totalOffside',
    'totalYellowCard',
    'totalRedCard',
  ];

  const calculateStatsMap = (matchStats) => {
    const homeStatsMap = {};
    const awayStatsMap = {};

    // Iterate over keys of matchStats
    Object.keys(matchStats).forEach((key) => {
      const stat = matchStats[key]; // Assuming each statistic has only one entry
      const { type, fh, sh, isHome } = stat;
      const stat1 = parseFloat(fh) || 0;
      const stat2 = parseFloat(sh) || 0;
      let combinedValue = stat1 + stat2;

      // Adjust possession percentage calculation
      if (type === 'possessionPercentage') {
        let avgPossession;
        if (stat2 === 0) {
          avgPossession = combinedValue;
        } else {
          avgPossession = combinedValue / 2;
        }
        combinedValue = Math.round(avgPossession); // Round the value to the nearest whole number
        combinedValue = `${combinedValue}%`;
      } else if (type === 'expectedGoals') {
        const xG = stat1 + stat2;
        combinedValue = xG.toFixed(2); // Keep the existing logic for expected goals
      }

      // Determine whether the stat is for home or away team
      if (isHome === 1) {
        // Populate homeStatsMap
        if (!homeStatsMap[type]) homeStatsMap[type] = [];
        homeStatsMap[type].push({ type, value: combinedValue });
      } else {
        // Populate awayStatsMap
        if (!awayStatsMap[type]) awayStatsMap[type] = [];
        awayStatsMap[type].push({ type, value: combinedValue });
      }
    });

    return { homeStatsMap, awayStatsMap };
  };
  const { homeStatsMap, awayStatsMap } = calculateStatsMap(matchStats);
  const homeStatsEntries = Object.entries(homeStatsMap);
  const awayStatsEntries = Object.entries(awayStatsMap);
  const combinedStatsEntries = homeStatsEntries.concat(awayStatsEntries);

  const getStatsEntries = (statsMap, statsType) => {
    const stats = statsMap[statsType] || [];
    return stats.length > 0 ? stats[stats.length - 1].value : 0;
  };

  const getShownStats = (requiredStats) => {
    return requiredStats
      .filter((type) =>
        combinedStatsEntries.some(([entryType]) => entryType === type)
      )
      .map((type) => {
        const translatedType = translateStatType(type);
        const homeValue = getStatsEntries(homeStatsMap, type);
        const awayValue = getStatsEntries(awayStatsMap, type);

        return {
          type: translatedType,
          homeValue: homeValue || 0,
          guestValue: awayValue || 0,
        };
      });
  };

  const shownStatsNonExpanded = getShownStats(requiredStatsNonExpanded);
  const shownStatsExpanded = getShownStats(requiredStatsExpanded);

  const mainOfficial = JSON.parse(
    matchDetails.matches[0].matchOfficialMain
  ).filter((official) => official.type === 'Main');
  const mainOfficialNames = mainOfficial
    .map((official) => `${official.firstName} ${official.lastName}`)
    .join(', ');
  const formatDate = (dateString, timeString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(`${dateString}T${timeString}`);
    const formattedDate = date.toLocaleDateString('hr-HR', options);
    const formattedTime = date.toLocaleTimeString('hr-HR', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${formattedDate} ${formattedTime}`;
  };

  const toggleStatsExpand = () => {
    setStatsExpanded(!statsExpanded);
  };
  const toggleDetailsExpand = () => {
    setDetailsExpanded(!detailsExpanded);
  };

  const gameInfo = [
    { type: 'Lokacija', gamelocation: matchDetails.matches[0].location },
    {
      type: 'Datum i vrijeme',
      gameDate: formatDate(
        matchDetails.matches[0].date,
        matchDetails.matches[0].time
      ),
    },
    { type: 'Sudac', gameJury: mainOfficialNames },
    // { type: "Prosjek kartona", cardsAvg: { red: 1.2, yellow: 3.4 } }, // You can leave this hardcoded for now
  ];
  const translateFoulText = (text) => {
    const translations = {
      Foul: 'Prekršaj',
      'Persistent Infringement': 'Učestalo kršenje pravila',
      Handball: 'Igranje rukom',
      dangerousPlay: 'Opasna igra',
      'Time wasting': 'Zadržavanje',
      Argument: 'Svađa',
      Dissent: 'Neslaganje',
      Simulation: 'Simuliranje',
      'Excessive celebration': 'Pretjerano slavlje',
      enchroachemnt: 'Zadiranje',
      leaveField: 'Izlazak iz terena',
      'Entering field': 'Ulazak u teren',
      'Off the ball foul': 'Prekršaj bez lopte',
      'Not Retreating': 'Nepovlačenje',
      enterRefreeArea: 'Ulaženje u sudački prostor tijekom pregleda',
      excessiveSignal: 'Pretjerana uporaba signala za pregled',
      'Other reason': 'Drugi razlozi',
      'Violent Conduct': 'Nasilno ponašanje',
      Spitting: 'Pljuvanje',
      'Professional Foul Last Player': 'Zadnji igrač',
      'Professional Foul Handball': 'Igranje rukom',
      'Serious Foul': 'Ozbiljan prekršaj',
      'Foul and Abusive Language': 'Prekršaj i uvredljiv riječnik',
    };

    return translations[text] || text;
  };
  const events = matchEvents.events || [];
  const details = matchDetails.matches || [];
  const substitutedPlayers = new Set();

  const renderMatchEvent = (event, index) => {
    switch (event.type) {
      case 1:
        return (
          <EventTypeGoal
            key={index}
            minute={event.minute}
            playerName={event.playerMain}
            isHome={event.isHome}
            currentScore={event.text}
            playerAssist={event.playerOther}
          />
        );
      case 2:
        return (
          <EventTypeYellowCard
            key={index}
            minute={event.minute}
            playerName={event.playerMain}
            isHome={event.isHome}
            foulReason={translateFoulText(event.text)}
          />
        );
      case 3:
        return (
          <EventTypeOwnGoal
            key={index}
            minute={event.minute}
            playerName={event.playerMain}
            isHome={!event.isHome}
            currentScore={event.text}
          />
        );
      case 4:
        return (
          <EventTypeRedCard
            key={index}
            minute={event.minute}
            playerName={event.playerMain}
            isHome={event.isHome}
            foulReason={translateFoulText(event.text)}
          />
        );
      case 6:
        return (
          <EventTypePenalty
            key={index}
            minute={event.minute}
            playerName={event.playerMain}
            isHome={event.isHome}
            currentScore={event.text}
            playerAssist={event.playerOther}
          />
        );
      case 7:
        if (substitutedPlayers.has(event.playerMain)) {
          return null; // Skip this event if the player has already been substituted
        }
        substitutedPlayers.add(event.playerMain);
        return (
          <EventTypeSubstitution
            key={index}
            minute={event.minute}
            playerName={event.playerMain}
            isHome={event.isHome}
            playerOut={event.playerOther}
          />
        );
      case 8:
        return (
          <EventTypeSecondYellow
            key={index}
            minute={event.minute}
            playerName={event.playerMain}
            isHome={event.isHome}
            foulReason={translateFoulText(event.text)}
          />
        );
        case 9:
          // Add condition to show the event only if playerAssist is "PM"
          if (event.text === "PS") {
            return null; // Skip this event if the penalty was saved
          }
          return (
            <EventTypeMissedPenalty
              key={index}
              minute={event.minute}
              playerName={event.playerMain}
              isHome={event.isHome}
              currentScore={event.text}
              playerAssist={event.playerAssist}
            />
          );
          case 17:
          return (
            <EventTypePenaltyShootOut
              key={index}
              minute={event.minute}
              playerName={event.playerMain}
              isHome={event.isHome}
              text={event.text}
            />
          );
      default:
        return null;
    }
  };

  let firstHalfEvents = [];
  let secondHalfEvents = [];
  let extendedFirstHalf = [];
  let extendedSecondHalf = [];
  let penalties = [];
  
  events.forEach((event) => {
    if (event.period === 1) {
      firstHalfEvents.push(event);
    }
    if (event.period === 2) {
      secondHalfEvents.push(event);
    } 
    if (event.period === 3) {
      extendedFirstHalf.push(event);
    } 
     if (event.period === 4){
      extendedSecondHalf.push(event);
    }
    else if (event.period === 5){
      penalties.push(event);
    }
  });

  events.sort((a, b) => a.period - b.period || parseInt(a.minute) - parseInt(b.minute));

  const firstHalfHomeGoals = firstHalfEvents.filter(
    (event) =>
      (event.type === 1 || event.type === 3 || event.type === 6) &&
      event.isHome === 1
  ).length;
  const firstHalfGuestGoals = firstHalfEvents.filter(
    (event) =>
      (event.type === 1 || event.type === 3 || event.type === 6) &&
      event.isHome !== 1
  ).length;
  const isHalftime =
    details[0].minute > 45 + details[0].announcedInjuryTimeFh / 60;

  return (
    <div>
      {matchStats.length > 0 && (
        <div>
        <div className="flex justify-around p-2">
          <table className="w-full table-fixed"> 
      <thead></thead>
   <tbody>
     <tr className="w-full"> 
       <td className="w-1/3 text-center"> 
         <img src={matchDetails.matches[0].homeTeam.logoUrl} alt="Home team" className="h-[40px] mx-auto" />
         <p className="text-text14 font-fw500 p-1">{matchDetails.matches[0].homeTeam.name}</p>
       </td>
       <td className="w-1/3 text-center p-2 text-text28 font-fw900"> 
            {matchDetails.matches[0].homeTeam.scored ?? 0}:
            {matchDetails.matches[0].guestTeam.scored ?? 0}
          </td>

       <td className="w-1/3 text-center"> {/* Set equal width to each cell */}
         <img src={matchDetails.matches[0].guestTeam.logoUrl} alt="Guest team" className="h-[40px] mx-auto"/>
         <p className="text-text14 font-fw500 p-1">{matchDetails.matches[0].guestTeam.name}</p>
       </td>
     </tr>
   </tbody>
 </table>
</div>
          <ExpandHeadingTitle
            title={'Statistika'}
            onClick={toggleStatsExpand}
          />
          <div className={`pl-3 pr-3 pt-2 ${statsExpanded ? 'true' : 'false'}`}>
            <table className="w-full">
              <tbody>
                {shownStatsNonExpanded.map((stat, index) => {
                  const homeStyle =
                    stat.homeValue > stat.guestValue
                      ? 'bg-successGreen text-white text-scoreText rounded-md flex items-center justify-center p-1 font-normal h-[24px]'
                      : 'font-normal text-black text-scoreText items-center justify-center';
                  const guestStyle =
                    stat.guestValue > stat.homeValue
                      ? 'bg-successGreen text-white text-scoreText rounded-md flex items-center justify-center p-1 font-normal h-[24px]'
                      : 'font-normal text-black text-scoreText items-center justify-center';

                  return (
                    <tr key={index} className="text-center">
                      <td className="w-[28px] h-[26px] pb-3">
                        <div className={homeStyle}>{stat.homeValue}</div>
                      </td>
                      <td className="pb-3 text-scoreText font-fw500">
                        {stat.type}
                      </td>
                      <td className="w-[28px] h-[26px] pb-3">
                        <div className={guestStyle}>{stat.guestValue}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {statsExpanded && (
            <div className="pl-3 pr-3">
              <table className="w-full">
                <tbody>
                  {shownStatsExpanded.map((stat, index) => {
                    const homeStyle =
                      stat.homeValue > stat.guestValue
                        ? 'bg-successGreen text-white text-scoreText rounded-md flex items-center justify-center p-1 font-normal h-[24px]'
                        : 'font-normal text-black text-scoreText items-center justify-center';
                    const guestStyle =
                      stat.guestValue > stat.homeValue
                        ? 'bg-successGreen text-white text-scoreText rounded-md flex items-center justify-center p-1 font-normal h-[24px]'
                        : 'font-normal text-black text-scoreText items-center justify-center';

                    return (
                      <tr key={index} className="text-center">
                        <td className="w-[28px] h-[26px] pb-3">
                          <div className={homeStyle}>{stat.homeValue}</div>
                        </td>
                        <td className="pb-3 text-scoreText">{stat.type}</td>
                        <td className="w-[28px] h-[26px] pb-3">
                          <div className={guestStyle}>{stat.guestValue}</div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      <ExpandHeadingTitle
        title={'Detalji utakmice'}
        onClick={toggleDetailsExpand}
      />
      <div className={` ${detailsExpanded ? 'true' : 'false'}`}>
        {!detailsExpanded && (
          <div>
            {/* Display only goal events when not expanded */}
            <div className="px-3 py-2">
              {events
                .filter(
                  (event) =>
                    event.type === 1 || event.type === 3 || event.type === 6
                )
                .map((event, index) => renderMatchEvent(event, index))}
            </div>
          </div>
        )}

        {detailsExpanded && (
          <div>
            {/* First half events */}
            <div className="px-3 py-2">
              {firstHalfEvents.map((event, index) =>
                renderMatchEvent(event, index)
              )}
            </div>
            {isHalftime && (
              <div className="bg-bgLight p-3 flex font-fw500">
                <img
                  src="/img/svg/ht.svg"
                  alt="Half time"
                  className="mr-1 h-[15px] pr-1"
                />{' '}
                <p className="text-lineupsText text-textSecondary pr-1">
                  {details[0].announcedInjuryTimeFh === 0
                    ? '45,'
                    : `45 + ${details[0].announcedInjuryTimeFh / 60},`}
                </p>
                <p className="text-lineupsText">
                  1.poluvrijeme ({firstHalfHomeGoals}:{firstHalfGuestGoals})
                </p>
                <img
                  src="/img/svg/info.svg"
                  alt="Info"
                  className="h-6 mr-1 h-[15px] ml-auto"
                />
              </div>
            )}
            
            {/* Second half events */}
            <div className="px-3 py-2">
              {secondHalfEvents.map((event, index) =>
                renderMatchEvent(event, index)
              )}
            </div>
            { extendedFirstHalf.length  > 0 && (
              <div className="bg-bgLight p-3 flex font-fw500">
                <img
                  src="/img/svg/ht.svg"
                  alt="Half Time"
                  className="h-6 mr-1 h-[15px] pr-1"
                />{' '}
                <p className="text-lineupsText text-textSecondary pr-1">
                {details[0].announcedInjuryTimeSh === 0
                    ? '90,'
                    : `90 + ${details[0].announcedInjuryTimeSh / 60},`}
                </p>
                  <p className='text-lineupsText'>2. poluvrijeme</p>
              </div>
            )}
             {/* Events extended first half */}
            <div className="px-3 py-2">
              {extendedFirstHalf.map((event, index) =>
                renderMatchEvent(event, index)
              )}
            </div>
            {extendedSecondHalf.length  > 0 && (
              <div className="bg-bgLight p-3 flex font-fw500">
                <img
                  src="/img/svg/ht.svg"
                  alt="Half Time"
                  className="h-6 mr-1 h-[15px] pr-1"
                />{' '}
                <p className="text-lineupsText text-textSecondary pr-1">
                   1. produžetak
                </p>
              </div>
            )}
            <div className="px-3 py-2">
              {extendedSecondHalf.map((event, index) =>
                renderMatchEvent(event, index)
              )}
            </div>
            {penalties.length  > 0 && (
              <div className="bg-bgLight p-3 flex font-fw500">
                <img
                  src="/img/svg/ht.svg"
                  alt="Half Time"
                  className="h-6 mr-1 h-[15px] pr-1"
                />{' '}
                <p className="text-lineupsText text-textSecondary pr-1">
                   2. produžetak
                </p>
              </div>
            )}
            <div className="px-3 py-2">
              {penalties.map((event, index) =>
                renderMatchEvent(event, index)
              )}
            </div>

            {penalties.length > 0 && details[0].played === 1 && (
            <div className="bg-bgLight p-3 flex font-fw500">
              <img
                src="/img/svg/ht.svg"
                alt="Half Time"
                className="h-6 mr-1 h-[15px] pr-1"
              />{' '}
              <p className="text-lineupsText text-textSecondary pr-1">
                Penali ({details[0].homeTeam.scored}:
                {details[0].guestTeam.scored})
              </p>
            </div>
          )}
          {details[0].played === 1  && penalties.length === 0 && extendedFirstHalf.length === 0 && extendedSecondHalf.length === 0 &&( 
            <div className="bg-bgLight p-3 flex font-fw500">
              <img
                src="/img/svg/ht.svg"
                alt="Half Time"
                className="h-6 mr-1 h-[15px] pr-1"
              />{' '}
              <p className="text-lineupsText text-textSecondary pr-1">
                {details[0].announcedInjuryTimeSh === 0
                  ? '90,'
                  : `90 + ${details[0].announcedInjuryTimeSh / 60},`}{' '}
              </p>
              <p className="text-lineupsText">
                Kraj ({details[0].homeTeam.scored}:
                {details[0].guestTeam.scored})
              </p>
            </div>
          )}
           {details[0].played === 1  && penalties.length === 0 && extendedFirstHalf.length !== 0 && extendedSecondHalf.length !== 0 &&( 
            <div className="bg-bgLight p-3 flex font-fw500">
              <img
                src="/img/svg/ht.svg"
                alt="Half Time"
                className="h-6 mr-1 h-[15px] pr-1"
              />{' '}
              <p className="text-lineupsText text-textSecondary pr-1">
                
              </p>
              <p className="text-lineupsText">
                Kraj ({details[0].homeTeam.scored}:
                {details[0].guestTeam.scored})
              </p>
            </div>
          )}
          
          </div>
          
        )}
      </div>
      {gameInfo.length > 0 && (
        <div>
          <HeadingTitle title={'Informacije o utakmici'} />
          <div className="flex">
            <table className="w-full">
              <tbody>
                {gameInfo.map((info, index) => (
                  <React.Fragment key={index}>
                    {(info.gamelocation ||
                      info.gameDate ||
                      info.gameJury ||
                      (info.cardsAvg &&
                        (info.cardsAvg.yellow > 0 ||
                          info.cardsAvg.red > 0))) && (
                      <tr>
                        <td>
                          <div className="flex-1 p-2 font-medium text-scoreText">
                            {info.type}
                          </div>
                        </td>
                        <td>
                          <div className="flex-1 text-right pr-2 font-normal text-scoreText">
                            <div className="flex items-center justify-end">
                              {info.gamelocation && (
                                <p className="">{info.gamelocation}</p>
                              )}
                              {info.gameDate && (
                                <p className="">{info.gameDate}</p>
                              )}
                              {info.gameJury && (
                                <p className="">{info.gameJury}</p>
                              )}
                              {info.cardsAvg && (
                                <React.Fragment>
                                  {info.cardsAvg.yellow > 0 && (
                                    <React.Fragment>
                                      <img
                                        src="/img/svg/yellowCard.svg"
                                        alt="Yellow Card"
                                        className="h-6 mr-1 h-[15px]"
                                      />
                                      <span className="pr-4">
                                        {info.cardsAvg.yellow}
                                      </span>
                                    </React.Fragment>
                                  )}
                                  {info.cardsAvg.red > 0 && (
                                    <React.Fragment>
                                      <img
                                        src="/img/svg/redCard.svg"
                                        alt="Red Card"
                                        className="h-6 mr-1 h-[15px]"
                                      />
                                      <span>{info.cardsAvg.red}</span>
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default MatchDetailsContent;
